import { PackDetailModel } from './package-detail.model';
import { CustomerLog } from './cutomerlog.model';
export class BusinessUserModel {
    _id: string;
    firstName: string;
    lastName: string;
    companyName: string;
    country: string;
    listingState: string;
    emailId: string;
    mobileNumber: string;
    countryCode: string;
    password: string;
    addressLine1: string;
    addressLine2: string;
    landMark: string;
    city: string;
    state: string;
    pincode: string;
    listingCompanyName: string;
    listingCountry: string;
    listingEmailId: string;
    listingMobileNumber: string;
    weblink: string;
    categoryName: string;
    subCategoryName: string;
    category: string;
    subCategory: Array<any>;
    packageDetails: [PackDetailModel];
    logImageName: string;
    companyImageName: any;
    customerLogs: [CustomerLog];
    checkID: string;
    razorpayOrderId: string;
    paymentId: string;
    razorpaySignature: string;
    duration: string;
    month: string;
    amount: string;
    grade: string;
    startingDate: Date;
    closingDate: DataCue;
    active: boolean;
    razorpaySubscribeId: string;
    centerCode: string;
    attribute: [
        {
            fieldName: String,
            fieldValue: String,
            fieldId: String,
            fieldValueId: String
        }
    ];
}
