export class ImageUpload {
    imageName: string;
    imagePath: string;
    width: number;
    height: number;
    imageBase64: any;
    folder: string;
    objId: string;
    customerBucket: string;
 }
 